<ng-container *transloco="let translate">

  <p-panel id="skills" [header]="translate('cv.skills.panelTitle')">
    <div class="skills">
      @if (!!_skills && !!_clones) {
        @for (skill of _skills; track skill.name) {
          <div class="skills__languages">
            <span>{{ skill.name }}</span>
            <p-progressBar [value]="skill.rate" [showValue]="false"></p-progressBar>
          </div>
        }
      }
    </div>
  </p-panel>
</ng-container>
