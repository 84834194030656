@if (selectedMission) {
  <p-dialog
    [(visible)]="isDialogDisplayed"
    [header]="selectedMission.client"
    [modal]="true"
    [style]="{ maxWidth: '960px' }"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    [blockScroll]="true"
    (onHide)="onDialogHiding()"
    (onShow)="onMissionLoading()"
  >
    <ng-template pTemplate="header">
      <div class="mission__title">
        <h2 class="prose prose-2xl font-bold text-slate-600 dark:text-slate-100">{{ selectedMission.client }}</h2>
        <span class="mission__title__timelapse">{{ selectedMission.timelapse }}</span>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div [class.mission__content-transition]="!isLoading" class="mission__content">
        @if (isLoading) {
          <p-progressSpinner strokeWidth="6" styleClass="mission__content__spinner"></p-progressSpinner>
        } @else {
          <div class="mission__content__text">
            <markdown [data]="innerLightMission"></markdown>
            <markdown [data]="innerFullMission" class="flex flex-col gap-3"></markdown>
          </div>
        }
      </div>
    </ng-template>
  </p-dialog>
}
