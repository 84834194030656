<ng-container *transloco="let translate">
  <p-panel id="hobbies" [header]="translate('cv.hobbies.panelTitle')">
    <div class="hobbies">
      <p-tag [rounded]="true">{{ translate('cv.hobbies.content.dev') }}</p-tag>
      <p-tag [rounded]="true">{{ translate('cv.hobbies.content.homelab') }}</p-tag>
      <p-tag [rounded]="true">{{ translate('cv.hobbies.content.movie') }}</p-tag>
      <p-tag [rounded]="true">{{ translate('cv.hobbies.content.basket') }}</p-tag>
      <p-tag [rounded]="true">{{ translate('cv.hobbies.content.running') }}</p-tag>
    </div>
  </p-panel>
</ng-container>
